(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ScheduleVisitDialogController',ScheduleVisitDialogController);

    ScheduleVisitDialogController.$inject = ['$mdDialog', '$timeout', 'moment', 'order', 'AppraisalReport'];

    function ScheduleVisitDialogController($mdDialog, $timeout, moment, order, AppraisalReport) {
        var vm = this;
        vm.appraisalOrder = order;
        vm.minDate = moment().toDate();
        var appraisalDueDate = moment(vm.appraisalOrder.activeReport.appraisalDueDate).toDate();
        if (!vm.appraisalOrder.rush && vm.minDate < appraisalDueDate) {
            vm.maxDate = appraisalDueDate;
        }
        vm.save = save;
        vm.cancel = cancel;

        function save() {
            // Combine date and time fields
            vm.visitScheduleDate.setHours(
                vm.visitScheduleDateTime.getHours());
            vm.visitScheduleDate.setMinutes(
                vm.visitScheduleDateTime.getMinutes());

            // Update the visit date for the active report
            vm.appraisalOrder.activeReport.visitScheduleDate = vm.visitScheduleDate;

            // Send update request to server and resolve the dialog with the result
            AppraisalReport.scheduleVisit(vm.appraisalOrder.activeReport, function(data) {
                $mdDialog.hide(data);
            }, function(error) {
                $mdDialog.cancel(error);
            });
        }

        function cancel(ev) {
            $mdDialog.cancel('cancel');
        }
    }
})();
