(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('DeclinedDialogController', DeclinedDialogController);

    DeclinedDialogController.$inject = ['$state', '$timeout', '$scope', '$stateParams', '$mdDialog', 'entity', 'Declined', 'AppraiserUser', 'AppraisalOrder', 'Principal'];

    function DeclinedDialogController ($state, $timeout, $scope, $stateParams, $mdDialog, entity, Declined, AppraiserUser, AppraisalOrder, Principal) {
        var vm = this;

        vm.declined = entity;
        vm.clear = clear;
        vm.save = save;
        vm.maxLength = 255;

        if (vm.declined.appraisalOrderId) {
            vm.appraisalOrder = AppraisalOrder.get({id: vm.declined.appraisalOrderId}).$promise.then( function(result) {
                // Assign appraisalUserId to vm.declined after we get it from the DTO.
                vm.declined.appraiserUserId = result.assignedAppraiserId;
            });
        } else {
            vm.declined.appraisalOrderId = vm.declined.appraisalOrder.id;
        }

        function clear () {
            $mdDialog.cancel();
        }

        function save () {
            vm.isSaving = true;
            if (vm.declined.id !== null) {
                Declined.update(vm.declined, onSaveSuccess, onSaveError);
            } else {
                Declined.save(vm.declined, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $mdDialog.hide();
            if (Principal.hasAnyAuthority([/^ROLE_(ADMIN|VC|CUSTOMER_CARE).*$/])) {
                $state.reload();
            } else {
                $state.go('home');
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
