(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('StandardResidentialDetailedImprovementsControllerCUSPAP2024',StandardResidentialDetailedImprovementsController2024);

    StandardResidentialDetailedImprovementsController2024.$inject = ['$log', '$scope', '$translate', '$timeout'];

    function StandardResidentialDetailedImprovementsController2024($log, $scope, $translate, $timeout) {

        // Initialize model
        var vm = this;
        var sectionData = null;
        vm.addLevel = addLevel;
        vm.addBathroom = addBathroom;
        vm.roomsForLevel = roomsForLevel;
        vm.unallocatedRooms = unallocatedRooms;
        vm.allocatedRoomsForLevel = allocatedRoomsForLevel;
        vm.levelOptions = Array.apply(null, {length: 7}).map(Number.call, Number);
        vm.hideRoom = hideRoom;
        vm.disableBasementArea = false
        vm.increaseLevelByDesignStyle = increaseLevelByDesignStyle;

        // Retrieve bathroom translation, then define method to generate bathroom names
        var bathroomTranslationPromise = $translate($scope.vm.translationPrefix + '.bathroom')
            .then(function (bathroomTranslation) {
                vm.getBathroomName = function (index) {
                    return bathroomTranslation + " #" + (index + 1);
                };
            }, function (translationId) {
                $log.error("Failed to find a translation for bathroom: ", translationId);
            });

        // Initialize model using form data
        $scope.vm.appraisalOrder.$promise
            .then(function () {
                init(null, $scope.vm.formData);
                $scope.$on('formDataReinitialized', init);
            }).catch(function(err) {
                console.error("Error fetching appraisalOrder...", err);
            });

        // checks if array2 only contains items found in array1
        function containsOnly(array1, array2) {
            return array2.every(function (elem) {
                return array1.includes(elem);
            });
        }

        function conditionallyDisableBasementArea(basementData) {
            var zeroSqFt = ["SLAB", "CRAWLSPACE"];

            // Disable for slab, crawl or if it is undefined.
            if (basementData === undefined || zeroSqFt.includes(basementData)) {
                if (sectionData.levels[0].area !== 0) {
                    sectionData.levels[0].area = 0;
                    $scope.vm.setDirty('improvementsDetailed.levels[' + 0 + '].area')
                }
                vm.disableBasementArea = true;
            }
        }

        function init(event, formData) {
            sectionData = formData.improvementsDetailed;
            var basementData = formData.improvements.basement;

            conditionallyDisableBasementArea(basementData);

            if (basementData === undefined) {
                var belowGradesLevels = getBelowGrades();
                if (belowGradesLevels.length > 0) {
                    belowGradesLevels.forEach(function (level, index) {
                        if (index !== 0) {
                            // For the Level which has been assigned and the basement options have been unchecked
                            level.belowGrade = false;
                            var basementRoomsCaseA = allocatedRoomsForLevel(index);
                            if (basementRoomsCaseA !== undefined && basementRoomsCaseA) {
                                basementRoomsCaseA.filter(function (room) {
                                    room.level = null;
                                });
                            }
                        } else {
                            // There are no basements. Remove rooms allocated.
                            var basementRoomsCaseB = allocatedRoomsForLevel(0);
                            if (basementRoomsCaseB !== undefined && basementRoomsCaseB) {
                                basementRoomsCaseB.filter(function (room) {
                                    room.level = null;
                                });
                            }
                        }
                    });
                }
            }

            // Initialize the number of levels using the allocated rooms
            var initLevels = getAllRooms().map(function (room) {
                return room.level;
            }).reduce(function (maxLevel, roomLevel) {
                return angular.isDefined(roomLevel) ?
                    Math.max(maxLevel, roomLevel) :
                    maxLevel;
            }, 1) + 1;


            // Extend the array of levels to account for any that are uninitialized
            while (initLevels > sectionData.levels.length) {
                sectionData.levels.push({});
            }

            // Initialize otherRooms the first time this report is accessed
            if (!sectionData.otherRoomsInitialized) {
                // Set otherRooms to a length of 7
                while (sectionData.otherRooms.length < 7) {
                    sectionData.otherRooms.push({images: [{}]});
                }

                // Add default names to rooms
                sectionData.otherRooms.forEach(function (room, index) {
                    // Skip rooms that already have a name, or that do not have a default name
                    if (room.name || index > 6) {
                        return;
                    }

                    $translate($scope.vm.translationPrefix + '.otherRooms.' + index)
                        .then(function (translation) {
                            room.name = room.name || translation;
                            $scope.vm.setDirty('improvementsDetailed.otherRooms[' + index + '].name');
                        }, function (translationId) {
                            $log.error("Failed to find a translation for room index " + index, translationId);
                        });
                });

                // Delete rooms that are hidden
                // TODO: Deprecate the 'isHidden' field. Note that it can only be deprecated after each
                // report has run through this code at least once to save a name for each room. Once
                // deprecated, this block of code can be removed and the 'otherRoomsInitialized' field
                // can be deprecated as well
                sectionData.otherRooms
                    .filter($scope.vm.isNotDeleted)
                    .forEach(function (room, index) {
                        if (room.isHidden) {
                            $scope.vm.removeItem(sectionData.otherRooms, index);
                        }
                    });
            }

            sectionData.otherRoomsInitialized = function () {
                return true;
            };

            var basementName = "Basement"
            if (basementData == 'OTHER') {
                basementName = formData.improvements.basementOther +  " Basement";
            } else if (basementData != null) {
                var translatedBasementName = $translate.instant("valueconnectApp.enum.BasementType." + basementData)
                basementName = translatedBasementName + " Basement"
            }

            if (sectionData.levels[0]) {
                sectionData.levels[0].name = basementName;
            }

            // Add default names to bathrooms
            bathroomTranslationPromise.then(function (bathroomTranslation) {
                sectionData.bathrooms.forEach(function (room, index) {
                    room.name = room.name || vm.getBathroomName(index);
                });
            });

            // Initialize calculated fields
            sectionData.numBedroomLarge = getBedroomsBySize('LARGE'); // <--- Returns a function
            sectionData.numBedroomAverage = getBedroomsBySize('AVERAGE'); // <--- Returns a function
            sectionData.numBedroomSmall = getBedroomsBySize('SMALL'); // <--- Returns a function

            sectionData.numBathroomFull = getBathroomsBySize('FULL');
            sectionData.numBathroomPartial = getBathroomsBySize('PARTIAL');
            sectionData.numBathroomFullAg = getBathroomsBySize('FULL', true);
            sectionData.numBathroomPartialAg = getBathroomsBySize('PARTIAL', true);

            // Init calculated fields for each level
            sectionData.levels.forEach(initLevel);

            // Order Totals
            sectionData.roomTotal = countTotal(getAllRoomsNoBathrooms); // <--- Returns a function
            sectionData.bedroomTotal = countTotal(sectionData.bedrooms); // <--- Returns a function
            sectionData.bathroomTotal = countTotal(sectionData.bathrooms); // <--- Returns a function
            sectionData.areaTotal = function () {
                return sectionData.levels.map(function (level) {
                    return level.area || 0;
                }).reduce(sum, 0);
            };

            // Above grade totals
            sectionData.agRoomTotal = countAboveGrade(getAllRoomsNoBathrooms);
            sectionData.agBedroomTotal = countAboveGrade(sectionData.bedrooms);
            sectionData.agBathroomTotal = countAboveGrade(sectionData.bathrooms);
            sectionData.agAreaTotal = function () {
                return sectionData.levels
                    .filter(function (level) {
                        return !level.belowGrade;
                    })
                    .map(function (level) {
                        return level.area || 0;
                    })
                    .reduce(sum, 0);
            };

        } // end function init()

        // Update level options when the number of levels changes
        $scope.$watch('vm.formData.improvementsDetailed.levels.filter(vm.isNotDeleted).length',
            function (numLevels, oldVal) {
                vm.levelOptions = Array.apply(null, {length: numLevels}).map(Number.call, Number);

                // Reset select boxes if a level was removed
                if (numLevels < oldVal) {
                    resetLevels(sectionData.bedrooms, 'bedrooms');
                    resetLevels(sectionData.bathrooms, 'bathrooms');
                    resetLevels(sectionData.otherRooms, 'otherRooms');
                }

                function resetLevels(rooms, collectionName) {
                    rooms.filter(function (room) {
                        return room.level > numLevels - 1;
                    }).forEach(function (room, index) {
                        room.level = null;
                        if ($scope.editForm['improvementsDetailed.' + collectionName + '[' + index + '].level'])
                            $scope.editForm['improvementsDetailed.' + collectionName + '[' + index + '].level'].$setDirty();
                    });
                }
            });

        /**
         * Return true if the room should be counted in totals, otherwise false
         */
        function isCounted(room) {
            return angular.isNumber(room.level) && room.notCounted !== true && room.hidden !== true && room.__deleted__ !== true;
        }

        /**
         * Get a function that will count the number of bedrooms with the specified size
         */
        function getBedroomsBySize(size) {
            return function() {
                return sectionData.bedrooms.filter(isCounted).filter(function(bedroom) {
                    return bedroom.size === size;
                }).length;
            };
        }

        /**
         * Get a function that will count the number of bathrooms with the specified size
         * @param  {String}  size       The size to filter by
         * @param  {Boolean} aboveGrade If true, filter to only count bathrooms above grade
         * @return {Function}           Function that will return the bathroom count
         */
        function getBathroomsBySize(size, aboveGrade) {
            return function() {
                var bathrooms = sectionData.bathrooms.filter(isCounted);
                if (aboveGrade === true) {
                    bathrooms = bathrooms.filter(isAboveGrade);
                }
                return bathrooms.filter(function (bedroom) {
                    return bedroom.size === size;
                }).length;
            }
        }

        /**
         * Initialize calculated fields on the provided level object
         */
        function initLevel(level, index) {
            // Initialize some fields if this level has not been started
            if (!level.$started) {
                $timeout(
                    function () {
                        // Mark basement as below grade
                        if (index === 0) {
                            level.belowGrade = true;
                            $scope.vm.setDirty('improvementsDetailed.levels[' + index + '].belowGrade');
                        }

                        // Set a translated value for this level name
                        $translate($scope.vm.translationPrefix + '.levels.name.' + index)
                            .then(function (levelName) {
                                level.name = levelName;
                                $scope.vm.setDirty('improvementsDetailed.levels[' + index + '].name');
                            });
                    });
            }

            level.roomTotal = function() {
                return roomsForLevel(index).length;
            };
            return level;
        }

        function addLevel() {
            var newLevel = initLevel({}, sectionData.levels.length);
            sectionData.levels.push(newLevel);
        }

        function addBathroom() {
            sectionData.bathrooms.push({
                name: vm.getBathroomName(sectionData.bathrooms.length),
                images: [{}]
            });
        }

        function getAllRoomsNoBathrooms() {
            return sectionData.bedrooms.map(function(bedroom, index) {
                bedroom.$name = "Bedroom #" + (index+1);
                return bedroom;
            }).concat(sectionData.otherRooms);
        }

        /**
         * Get a list of all room objects, generating a name field for bedrooms and bathrooms
         */
        // TODO: translate bedroom/bathroom
        function getAllRooms() {
            return getAllRoomsNoBathrooms()
                .concat(sectionData.bathrooms.map(function(bathroom, index) {
                    bathroom.$name = "Bathroom #" + (index+1);
                    return bathroom;
                }));
        }

        /**
         * Check if the provided room is above grade. If the room level is not defined, false
         * is returned
         *
         * @param  {Object}  room The room to check
         * @return {Boolean}      True if the room is above grade, otherwise false
         */
        function isAboveGrade(room) {
            if (angular.isUndefined(room.level)) {
                return false;
            }
            var level = sectionData.levels[room.level];
            if (angular.isUndefined(level)) {
                return false;
            }
            var belowGrade = angular.isFunction(level.belowGrade) ? level.belowGrade() : level.belowGrade;
            return !belowGrade;
        }

        function getBelowGrades() {
            return sectionData.levels.filter(function (level) {
                return angular.isDefined(level.belowGrade) && level.belowGrade === true;
            });
        }

        /**
         * Get a function that will count the number of provided rooms that are above grade
         * @param  {Array|Function} rooms An array of rooms objects to count, or a function
         *                                that will return an array of room objects to count
         */
        function countAboveGrade(rooms) {
            return function() {
                var roomArray = angular.isFunction(rooms) ? rooms() : rooms;
                return roomArray.filter(isAboveGrade).filter(isCounted).filter($scope.vm.isNotDeleted).length;
            };
        }

        /**
         * Get a function that will count the number of provided rooms
         * @param  {Array|Function} rooms An array of rooms objects to count, or a fucntion
         *                                that will return an array of room objects to count
         */
        function countTotal(rooms) {
            return function() {
                var roomArray = angular.isFunction(rooms) ? rooms() : rooms;
                return roomArray.filter(isCounted).length;
            };
        }

        /**
         * Get a list of room names that have been allocated to the specified level
         * @param  {Int}   level The level to check
         */
        function roomsForLevel(level) {
            return getAllRoomsNoBathrooms()
                .filter(isCounted)
                .filter(function(room) {
                    return room.level === level;
                })
                .map(function(room) {
                    return room.name || room.$name;
                });
        }

        /**
         * Get a list of room names that have not been allocated
         */
        function unallocatedRooms() {
            return getAllRooms()
                .filter(function(room) {
                    return angular.isUndefined(room.level) || room.level === null;
                })
                .map(function(room) {
                    return room.name || room.$name;
                });
        }

        /*
        * Get allocated rooms for a level
        */
        function allocatedRoomsForLevel(level){
            return getAllRooms()
                .filter(function(room) {
                    return (angular.isDefined(room.level) || room.level !=null) && room.level === level;
                });
        }

        /**
         * Mark the room as hidden. This is only used for the pre-populated rooms, so that array
         * indices are not shifted by deleting them.
         * @param  {Integer} $index The index of the room in the otherRooms array
         */
        function hideRoom($index) {
            var fieldName = 'improvementsDetailed.otherRooms['+$index+'].hidden';
            var field = $scope.editForm[fieldName];

            if (!field) {
                $log.error('Cannot update non-existent field:', fieldName);
            } else {
                field.$setViewValue(true);
                field.$setDirty();
            }
        }

        function sum(a,b) {
            return a+b;
        }

        function increaseLevelByDesignStyle(selectedDesignStyle) {
            console.log("this is selectedDesignStyle", selectedDesignStyle);

            var designStyleNumLevelMapping = {
                "ONE_STOREY": 1,
                "ONE_AND_HALF_STOREY": 2,
                "TWO_STOREY": 2,
                "TWO_AND_HALF_STOREY": 3,
                "THREE_OR_MORE_STOREY": 3,
                "THREE_LEVEL_SPLIT": 3,
                "FOUR_LEVEL_SPLIT": 4,
                "FIVE_LEVEL_SPLIT": 4,
                "A_FRAME": 1,
                "BI_LEVEL": 2,
                "BUNGALOW_RAISED": 1,
                "LOFT": 1,
                "MANUFACTURED": 1,
                "MOBILE_TRAILER_SINGLE": 1,
                "MOBILE_TRAILER_DOUBLE": 1,
                "MODULAR": 0,
                "OTHER": 0
            };

            for (var i = sectionData.levels.length; i <= designStyleNumLevelMapping[selectedDesignStyle]; i++) {
                this.addLevel();
            }
        }
    }
})();
