(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('AppraisalOrderContact', AppraisalOrderContact);

    AppraisalOrderContact.$inject = ['$resource'];

    function AppraisalOrderContact ($resource) {
        var resourceUrl =  'api/appraisal-order-contacts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getcontactbyappraisalorderid': {method: 'GET', url:'api/appraisal-order-contacts-from-appraisal-order-id/:id', isArray:true }
        });
    }
})();
