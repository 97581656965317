(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('AppraiserUser', AppraiserUser);

    AppraiserUser.$inject = ['$resource'];

    function AppraiserUser ($resource) {
        var resourceUrl =  'api/appraiser-users/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getCosigners': { method: 'GET', isArray: true, url: 'api/appraiser-users/cosigners/:orderId'},
            'getEligible': { method: 'GET', isArray: true, url: 'api/appraiser-users/eligible/:id'}, //Ignore blackouts and declines
            'getSuggested': { method: 'GET', isArray: true, url: 'api/appraiser-users/suggested/:id'}, //Respect above, next in line
            'getAvailableAssistants': { method: 'GET', isArray: true, url: 'api/appraiser-users/available-assistants/:appraisalFirmId'},
            'getAppraiserCoverage': { method: 'GET', url: 'api/appraiser-users/coverage'},
            'getMinimal': { method: 'GET', url:'api/appraiser-users-minimal', isArray: true}
        });
    }
})();
